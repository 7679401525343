import '../css/admin.scss';

import 'jquery';

/* Bootstrap modules - comment out as required */
import './bs4js/alert';
import './bs4js/button';
import './bs4js/collapse';
import './bs4js/dropdown';
import './bs4js/modal';

import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'lazysizes';
const TomSelect = require("tom-select");

import './argon.js';
import './components/CKReadMore';
import './components/ImageUploader';
import './components/ImageUploaderMulti';
import './components/ItemReordering';
import editor from './components/MapEditor';

(global as any).MapEditor = editor;


var editors = document.querySelectorAll(".html-form-control > textarea");
if (editors.length > 0)
{
    for (let i = 0; i < editors.length; i++)
    {
        const editor = editors[i] as HTMLTextAreaElement;
        CKEDITOR.inline(editor, {
            customConfig: '/site/ckconfig.js'
        });
    }
}


const extendedSelects = document.querySelectorAll("form select[dbs-select]");
for (let i = 0; i < extendedSelects.length; i++)
{
    const selectItem = extendedSelects[i] as HTMLSelectElement;
    new TomSelect(`#${selectItem.id}`, {
        create: selectItem.dataset.create
            ? function (input: string, callback: Function)
            {
                if (!selectItem.dataset.create || !selectItem.form)
                {
                    return;
                }

                const rvt = selectItem.form.querySelector("input[name=__RequestVerificationToken]") as HTMLInputElement;
                if (!rvt)
                {
                    return;
                }

                const xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function ()
                {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200)
                    {
                        callback({ value: xhr.responseText, text: input });
                    }

                    callback(false);
                }

                xhr.open("POST", selectItem.dataset.create, true);

                const data = new FormData();
                data.append("name", input);
                data.append("__RequestVerificationToken", rvt.value);
                xhr.send(data);
            }
            : false
    });
}